import { Menu } from "@mui/icons-material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { navlink } from "../data/dummydata";
import logo from "../data/images/logo.png";

export const Header = () => {
  const [responsive, setResponsive] = useState(false);
  const history = useHistory();

  const redirectToHome = () => {
    history.push("/");
  };

  const handleMenuItemClick = () => {
    setResponsive(false); 
  };

  return (
    <>
      <header>
        <div className='container flexsb'>
          <div className='logo' onClick={redirectToHome}>
            <img
              src={logo}
              alt=''
              data-aos='zoom-in-right'
              className='logo-img'
            />
          </div>
          <div className={responsive ? "hideMenu" : "nav"}>
            {navlink.map((links, i) => (
              links.external ? (
                <a
                  href={links.url}
                  key={i}
                  data-aos='zoom-in-left'
                  className="menu-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleMenuItemClick}
                >
                  {links.text}
                </a>
              ) : (
                <Link
                  to={links.url}
                  key={i}
                  data-aos='zoom-in-left'
                  className="menu-item"
                  onClick={handleMenuItemClick}
                >
                  {links.text}
                </Link>
              )
            ))}
          </div>
          <button
            className='toggle'
            onClick={() => setResponsive(!responsive)}
          >
            <Menu className='icon' />
          </button>
        </div>
      </header>
    </>
  );
};
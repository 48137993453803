import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Footer from "../common/Footer"
import { Header } from "../common/Header"
import { Home } from "../home/Home"
import { About } from "./About"
import { Team } from "./Team"
import { Contact } from "./Contact"
import { Services } from "./Services"
import { Cooperation } from "./Cooperation"
export const Pages = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/o_mnie' component={About} />
          <Route exact path='/oferta' component={Services} />
          <Route exact path='/wspolpraca' component={Cooperation} />
          <Route exact path='/zespol' component={Team} />
          <Route exact path='/kontakt' component={Contact} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

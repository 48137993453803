import React from "react"
import { Heading } from "../common/Heading"
import { contact } from "../data/dummydata"

export const Contact = () => {
  return (
    <>
      <div className='contact' id="contact">
        <div className='container'>
          <Heading title='Kontakt' />
          <div className='content flexsb'>
          <div className='right'>
          <img src="images/mapa.png" width="70%" data-aos='zoom-in'/>
      </div>
            <div className='left' >
              {contact.map((item) => (
                <div className='box' data-aos='zoom-in'>
                  <i>{item.icon}</i>
                  <p className='p1'>{item.text1}</p>
                  <p className='p2'>{item.text2}</p>
                  <p className='p3'>{item.text3}</p>
                  <p className='p4'>{item.text4}</p>
                  <p className='p5'>{item.text5}</p>
                  <p className='p6'>{item.text6}</p>
                  <p className='p7'>{item.text7}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

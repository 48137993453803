import { Settings, CropRotate, ViewInAr, PieChart, Code, BarChart, CloudOutlined, FavoriteBorder, Public, PersonOutlined, AddLocationAltOutlined, PhoneIphone, EmailOutlined, Facebook, Twitter, Instagram, YouTube, VideoLibrary, School, BuildCircle, CodeSharp, CodeRounded, CodeTwoTone, Computer, Language, SmartToy, DevicesFold, Devices } from "@mui/icons-material"
import Build from "react-countup"

export const navlink = [
  {
    url: "/",
    text: "Strona główna",
  },
  {
    url: "https://dev-com.pl/szkolenia",
    text: "Szkolenia",
    external: true,
  },
  {
    url: "/o_mnie",
    text: "O nas",
  },
  {
    url: "/oferta",
    text: "Oferta",
  },
  {
    url: "/wspolpraca",
    text: "Współpraca",
  },
  {
    url: "/zespol",
    text: "Zespół",
  },
  {
    url: "/kontakt",
    text: "Kontakt",
  },
]
export const home = [
  {
    text: "WITAJ NA NASZEJ STRONIE!",
    name: "DEV-COM",
    post: "DEV-COM",
    design: "DEV-COM",
    desc: "Serdecznie zapraszamy Cię do zapoznania się z naszą ofertą.",
  }
]
export const about = [
  {
    desc: "Istniejemy od 2008 roku, przez ten czas zdobyliśmy zaufanie wielu klientów, dostarczając im najwyższej jakości usługi programistyczne i edukacyjne. ",
    desc1: "Nasza firma składa się z doświadczonych i wykwalifikowanych specjalistów, którzy łączą swoją pasję i wiedzę, aby dostarczyć rozwiązania dostosowane do indywidualnych potrzeb i celów biznesowych naszych klientów.", 
    desc2: "W 2018 roku zdecydowaliśmy się dzielić swoją wiedzą z innymi. Od tego czasu przeszkoliliśmy wiele osób, ucząc zarówno podstaw programowania, jak i jego bardziej zaawansowanych technik.",
    cover: "./images/coding-man-2.jpg",
  },
]
export const services = [
  {
    id: 1,
    icon: <Devices />,
    title: "Tworzenie oprogramowania",
    desc: "Zajmujemy się tworzeniem zarówno front-endu, jak i back-endu, wykorzystując najnowsze technologie i najlepsze praktyki.",
  },
  {
    id: 2,
    icon: <VideoLibrary />,
    title: "Informatyka śledcza",
    desc: "Zajmujemy się informatyką śledczą, wykorzystując zaawansowane metody analizy danych cyfrowych do identyfikacji, zachowania i analizy dowodów elektronicznych.",
  },
  {
    id: 3,
    icon: <School />,
    title: "Organizowanie i przeprowadzanie kursów programowania",
    desc: "Oferujemy szkolenia zarówno dla osób prywatnych, jak i firm, dostosowane do różnych poziomów zaawansowania.",
  },
  {
    id: 4,
    icon: <SmartToy/>,
    title: "Projektowanie i przeprowadzanie procesów uczenia maszynowego oraz głębokiego",
    desc: "Specjalizujemy się w rozwijaniu i wdrażaniu rozwiązań opartych na sztucznej inteligencji, dostosowanych do indywidualnych potrzeb i celów biznesowych.",
  },
]
export const cooperation = [
  {
    id: 'intro',
    desc1: 'Czy masz projekt, który wymaga ekspertyzy w programowaniu? A może potrzebujesz wsparcia w szkoleniu swojego zespołu? Jesteś we właściwym miejscu!',
    desc2: 'Zapraszam do współpracy zarówno indywidualnych klientów, jak i przedsiębiorstwa. Wspólnie możemy zrealizować Twoje cele, dostarczając rozwiązania dostosowane do Twoich potrzeb i wymagań.',
  },
  {
    id: 'title',
    desc3: 'Jak rozpocząć współpracę? To proste:',
  },
  {
    id: 'summary',
    desc1: 'Twoja satysfakcja jest dla mnie priorytetem, dlatego oferuję elastyczne warunki współpracy i jestem zawsze dostępny, aby odpowiedzieć na wszelkie pytania.',
    desc2: 'Czekam na Ciebie i Twój projekt. Skontaktuj się ze mną już dziś i zobacz, jak mogę Ci pomóc!',
  },
  {
    id: 1,
    text: "KROK 1",
    image: "./images/cooperation/team-1.jpg",
    name: "Skontaktuj się z nami.",
    post: "Wyślij wiadomość e-mail. Bez tego nie zaczniemy :)",
  },
  {
    id: 2,
    text: "KROK 2",
    image: "./images/cooperation/team-2.jpg",
    name: "Omówmy Twój projekt:",
    post: "Podczas bezpłatnej konsultacji omówimy szczegóły Twojego projektu, Twoje cele i oczekiwania.",
  },
  {
    id: 3,
    text: "KROK 3",
    image: "./images/cooperation/team-3.jpg",
    name: "Dostosujmy plan:",
    post: "Wspólnie stworzymy plan działania, który najlepiej pasuje do Twojego projektu i budżetu.",
  },
  {
    id: 4,
    text: "KROK 4",
    image: "./images/cooperation/team-4.jpg",
    name: "Rozpocznijmy pracę:",
    post: "Po zatwierdzeniu planu, rozpoczniemy pracę, trzymając Cię na bieżąco z postępami i dostarczając regularne aktualizacje.",
  },
]
export const team = [
  {
    id: 'intro',
    desc1: 'Tworzenie wyjątkowych rozwiązań programistycznych i oferowanie najwyższej jakości usług edukacyjnych wymaga nie tylko wiedzy i umiejętności, ale także dedykowanego zespołu ekspertów.',
    desc2: 'Nasz zespół składa się z wykwalifikowanych i doświadczonych programistów, projektantów, analityków i trenerów, którzy łączą swoją pasję i ekspertyzę, aby dostarczyć najwyższą jakość w każdym projekcie. Każdy z nas jest specjalistą w swoim obszarze, co pozwala na tworzenie kompleksowych i spójnych rozwiązań, które spełniają oczekiwania naszych klientów.',
  },
  {
    id: 'title',
    desc3: 'Dlaczego warto nam zaufać?',
  },
  {
    id: 1,
    title: "Doświadczenie",
    desc: "Posiadamy bogate doświadczenie w różnych dziedzinach programowania, projektowania i nauczania.",
    cover: "./images/team/m_pexels-mikhail-nilov-8937596.jpg",
  },
  {
    id: 2,
    title: "Profesjonalizm",
    desc: "Stawiamy na rzetelność, terminowość i transparentność we współpracy z klientami.",
    cover: "./images/team/pexels-fauxels-3184418.jpg",
  },
  {
    id: 3,
    title: "Innowacyjność",
    desc: "Korzystamy z najnowszych technologii i metodologii, aby zapewnić innowacyjne i efektywne rozwiązania.",
    cover: "./images/team/m_pexels-thisisengineering-3861959-2.jpg",
  },
  {
    id: 4,
    title: "Indywidualne podejście",
    desc: "Dostosowujemy się do unikalnych potrzeb i celów każdego klienta, tworząc rozwiązania na miarę.",
    cover: "./images/team/pexels-fauxels-3184465.jpg",
  },
  {
    id: 5,
    title: "Wsparcie i komunikacja",
    desc: "Jesteśmy zawsze dostępni, aby odpowiedzieć na pytania i zapewnić wsparcie na każdym etapie projektu.",
    cover: "./images/team/pexels-jessica-lewis-3361492.jpg",
  },
]
export const contact = [
  {
    icon: <AddLocationAltOutlined />,
    text1: "DEV-COM Software House",
    // text2: "Tomasz Kaniecki",
    text3: "(Naszym operatorem księgowym jest: ",
    text4: "Fundacja Firma Dla Każdego)",
    text5: "Lwowska 5/15",
    text6: "00-660 Warszawa",
    text7: "NIP: 5252625624",
    cover: "./images/mapa.png",
  },
  /*
  {
    icon: <PhoneIphone />,
    text1: "0123456789",
  }, */
  {
  
    text1: "biuro@dev-com.pl",
  },
]
/*
export const social = [
  {
    icon: <Facebook />,
  },
  {
    icon: <Twitter />,
  },
  {
    icon: <Instagram />,
  },
  {
    icon: <YouTube />,
  },
]
*/
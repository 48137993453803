import React from "react";
import { Heading } from "../common/Heading"; 
import { team } from "../data/dummydata"; 

export const Team = () => {
  const introductionSection = team.find((item) => item.id === 'intro');
  const titleSection = team.find((item) => item.id === 'title');

  return (
    <>
      <section className='team' id='team'>
        <div className='container'>
          <Heading title='Zespół' />
          <div className='introduction' data-aos='fade-right'>
            <p className='intro-text'>{introductionSection.desc1}</p>
            <p className='intro-text2'>{introductionSection.desc2}</p>
          </div>
          <div className="title-box">
          <div className='title' data-aos='flip-down'>
            <h3 className='title-text'>{titleSection.desc3}</h3>
          </div>
          <div className='content'>
            {team.slice(2).map((item) => (
              <div className='box' data-aos='flip-left' key={item.id}>
                <div className='img' data-aos='fade-up'>
                  <img src={item.cover} alt='' data-aos='fade-down' />
                </div>
                <div className='text'>
                  <h3 data-aos='fade-right'>{item.title}</h3>
                  <p data-aos='fade-up-right'>{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
      </section>
    </>
  );
};
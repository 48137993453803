import React from "react"
import Slider from "react-slick"
import { Heading } from "../common/Heading"; 
import { cooperation } from "../data/dummydata"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Cooperation.css"

export const Cooperation = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const introductionSection = cooperation.find((item) => item.id === 'intro');
  const titleSection = cooperation.find((item) => item.id === 'title');
  const summarySection = cooperation.find((item) => item.id === 'summary');

  const filteredCooperation = cooperation.filter(item => [1, 2, 3, 4].includes(item.id));

  return (
    <>
      <section className='cooperation' id="cooperation">
        <div className='container'>
          <Heading title='Współpraca' />
          <div className='introduction' data-aos='fade-right'>
            <p className='intro-text'>{introductionSection.desc1}</p>
            <p className='intro-text2'>{introductionSection.desc2}</p>
          </div>
          <div className='title' data-aos='fade-down'>
            <h3 className='title-text'>{titleSection.desc3}</h3>
          </div>
          <Slider {...settings}>
            {filteredCooperation.map((val) => (
              <div className='box' key={val.id}>
                <p data-aos='zoom-out-down'>{val.text}</p>
                <div className='img' data-aos='zoom-out-right'>
                  <img src={val.image} alt='' />
                </div>
                <h3 data-aos='zoom-out-left'>{val.name}</h3>
                <label data-aos='zoom-out'>{val.post}</label>
              </div>
            ))}
          </Slider>
          <div className='summary' data-aos='fade-right'>
          <p className='summary-text'>{summarySection.desc1}</p>
          <p className='summary-text'>{summarySection.desc2}</p>
          </div>
        </div>
      </section>
    </>
  )
}
import React from "react"
import { social } from "../data/dummydata"

const Footer = () => {
  return (
    <>
      <footer>
        {/*
        {social.map((item) => (
          <>
            <i data-aos='zoom-in'>{item.icon}</i> 
          </>
        ))}
        */}
        <p>Wszelkie prawa zastrzeżone 2023 | Aneta Zbrzeźniak</p>
      </footer>
    </>
  )
}

export default Footer
